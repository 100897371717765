<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>评价管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <!-- 顶部搜索区域 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" @click="addobj()">添加组合</el-button>
        </el-col>
      </el-row>

      <!-- 商品列表区域 -->
      <el-table :data="goodsList" :span-method="objectSpanMethod" border style="width: 100%">
        
        <el-table-column prop="name" label="关联名称"> </el-table-column>
        <el-table-column label="商品图片">
          <template slot-scope="scope" width>
            <img v-if="!!scope.row.mainPic" :src="scope.row.mainPic" style="width: 100px; height: 100px; border: none" />
          </template>
        </el-table-column>
        <el-table-column prop="title" label="商品名称"></el-table-column>
        <el-table-column label="操作" align="center" width="300px">
          <template slot-scope="scope">
            <el-button type="primary" @click="addobj(scope.row)">编辑</el-button>
            <el-button type="danger" @click="removeGoodsById(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="Pagination">
      </div>
    </el-card>

  </div>
</template>

<script>
export default {
  data () {
    return {
      //  mock 模拟数据
      // 商品数据列表
      goodsList: [],
      originList: [],
      // 分页
      pages: {
        query: '',
        currentPage: 1,
        pageSize: 10,
      },

      total: 0,


      // 商品Id
      goodsId: [],
    }
  },
  created () {
    // 商品列表
    this.getGoods()
  },
  components: {
  },
  methods: {
    objectSpanMethod({ row, column, rowIndex, columnIndex }){
      if(columnIndex == 0 || columnIndex == 3){
        if(row.flag){
          return [row.goodsCount, 1]
        }else {
          return [0, 1]
        }
      }
      return [1, 1]
    },
    //  获取商品列表信息
    getGoods () {
      this.$api.GetGoodsEvaluateGroupList(this.pages).then((res) => {
        if (res.code !== 200) return this.$message.error('请求商品失败')
        const list = [];
        res.data.map(group => {
          const count = group.goodsEntityList.length;
          if(count > 0){
            group.goodsEntityList.map((good, idx) => {
                good.flag = idx == 0;
                good.id = group.id;
                good.name = group.name;
                good.goodsCount = count;
                good.groupId = group.groupId;
                list.push(good)
            })
          }else{
              list.push({
                flag: true,
                id: group.id,
                name: group.name,
                goodsCount: 1,
                groupId: group.groupId,
                title: '暂无关联商品'
              })
          }
          
        })
        this.originList = res.data;
        this.goodsList = list
      })
    },

    // 监听页码大小 也就是下拉框
    handleSizeChange (newSize) {
      this.pages.pageSize = newSize
      // 刷新列表
      this.getGoods()
    },

    // 监听当前页的转换 也就是点击数字
    handleCurrentChange (newPage) {
      this.pages.currentPage = newPage
      // 刷新列表
      this.getGoods()
    },

    // 点击跳转到添加商品列表
    addobj (data) {
      console.log(data)
      let p = '';
      if(data){
        p = '?id=' + data.groupId;
        var groupInfo = this.originList.find(o => o.groupId == data.groupId);
        localStorage.setItem(data.groupId, JSON.stringify(groupInfo));
      }
      this.$router.push('/addCommentGoods' + p)
    },

    // 删除商品
    async removeGoodsById (id) {
      const confirmResult = await this.$confirm(
        '此操作将永久删除该商品组合, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)
      
      if (confirmResult !== 'confirm') {
        return 
      }
      // 下面发起真正的删除请求
      this.$api.DeleteGoodsEvaluateGroup({id}).then((res) => {
        if (res.code !== 200) return this.$message.error('删除商品失败')
        this.$message.success('删除商品成功')
        this.getGoods()
      })
    },

  },
}
</script>

<style lang="scss" scoped></style>
